'use client';

import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';
import { Button } from 'modules/theme/components/button/Button';
import { Box } from '@mui/material';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { Svg } from 'modules/theme/components/svg/Svg';
import Image500 from 'components/page/error/svg/500.svg';


interface IProps {
    errorPageTitle: string;
    errorPageText: string;
    reloadButtonText: string;
    contactButtonText: string;
}

const Main: FC<IProps> = ({ errorPageTitle,  errorPageText, reloadButtonText, contactButtonText }) => {

    const redirectToContactsPage = () => {
        window.location.href = `${AppConfigService.getNehnutelnostiUrl()}/kontakty`;
    };

    const reloadPage = () => {
        window.location.reload();
    };

    // free wraped box rewrite next.js hmm... i dont know why
    return (
        <Box>
            <Box>
                <Box>
                    <Box sx={{
                        maxWidth: 577,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 2,
                        mx: 'auto',
                    }}>
                        <Svg icon component={Image500} width={241} />
                        <Box>
                            <Text variant={'h1'} semibold center>{errorPageTitle}</Text>
                        </Box>
                        <Box>
                            <Text variant={'body1'} center color={palette => palette.labelSecondary}>{errorPageText}</Text>
                        </Box>
                        <Box mt={1} display={{xs: 'block', md: 'none'}}>
                            <Button
                                small
                                text={reloadButtonText}
                                onClick={reloadPage}
                            />
                        </Box>
                        <Box mt={1} display={{xs: 'none', md: 'block'}}>
                            <Button
                                text={reloadButtonText}
                                onClick={reloadPage}
                            />
                        </Box>

                        <Box display={{xs: 'block', md: 'none'}}>
                            <Button
                                small
                                text={<Box fontWeight={600}>{contactButtonText}</Box>}
                                onClick={redirectToContactsPage}
                                textBtn
                            />
                        </Box>
                        <Box display={{xs: 'none', md: 'block'}}>
                            <Button
                                text={<Box fontWeight={600}>{contactButtonText}</Box>}
                                onClick={redirectToContactsPage}
                                textBtn
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Main;
